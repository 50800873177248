import { Http } from "api/Http";
import { sha256 } from "js-sha256";

const Api = new Http("login/1");

const Login = {
  /**
   * Submits the username and a hashed password to the api
   */
  login: async (username, password) => {
    let postData = {
      clientType: 8,
      username: username,
      password: sha256(password)
    };
    let res = await Api.post("/login", postData);
    return typeof res === "object" && res.headers.jwt;
  },

  /**
   * Gets a new token after x number of minutes
   *
   * The new token is handled in the api response interceptors
   * in api/config.js
   */
  keepAlive: () => {
    return Api.patch("/keepalive");
  }
};

export default Login;
